@media screen and (max-width: 800px) {
  .left, .center, .right {
    margin: 5%; 
    width: 80%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}
@media screen and (min-width: 800px) {
  .left, .right {
    float: left;
    width: 20%; /* The width is 20%, by default */
    border: 3px solid green;
  }

  .center {
    margin-top: 25px;
    margin-left: 75px;
    width: 600px;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
}

.body {
  /* border: 3px solid grey; */
  border-radius: 15px;
  padding: 20px;
}

li { 
  padding: 2px;
}
