
@media screen and (max-width: 800px) {
    .footer {
        
    }
  }
  @media screen and (min-width: 800px) {
    .footer {
        position: absolute;
        bottom: 50px;
    }
  }
  